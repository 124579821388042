.supplierHeader {

    position: fixed;
    flex: auto;
    background: #fff;
    height: 100px;
    padding: 1.5rem 0 1.5rem 0;
    box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
    z-index: 90;
}

.supplierHeader .left {

    width: 250px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: transparent;
    transition: all .2s ease;
}

.supplierHeader .headers {
    display: flex;
    width: 100%;
    align-items: center;
}

.supplierHeader .left a {
    width: 100%;
    text-align: center;


}

.supplierHeader a {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #888;
    font-size: 13px;
}

.supplierHeader .anticon {
    margin-right: 5px;
    margin-left: 5px;
}

.bg-primary {
    background-color: #410099 !important;
}


.justify-content {
    justify-content: space-around;
}

.supplierHeader .left img {

    height: 60px;
}

.supplierHeader .middle {
    width: 250px;

}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 7px 7px 0 !important;
    background-color: #410099 !important;
    border-color: #fff !important;
}



.supplierHeader .right {
    display: flex;
    width: calc(100% - 500px);
    justify-content: flex-end;
    padding-right: 50px;
}


.supplierHeader .right ul {
    display: flex;
    list-style: none;
    align-items: center;
}

.supplierHeader .right ul li {
    margin-right: 15px;
}

.mains .middle .card {
    margin-bottom: 15px;
}

.mains .middle .card .card-body .item {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;
    justify-content: space-around;
}

.mains .middle .card .card-body {
    padding: 20px;
}


.mains .middle .card .card-body .item .deg {
    margin-left: 30px;
    font-weight: 600;
}

.mains .middle .card .card-body .compare {
    font-size: 16px;
    color: #72849a !important
}

.mains .middle .card .card-body .lastweektitle {
    font-size: 12px;
    color: #72849a !important
}

.mains .middle .card .card-body .item .deg {
    margin-left: 30px;
    font-weight: 600;
}

.mains .middle .card .card-body .titleSub {
    font-size: 0.8rem;
    color: #72849a !important;
}

.mains .middle .card .card-body .reveneuPriceParent {
    align-items: center;
    padding-bottom: 18px;
}

.mains .middle .card .card-body .reveneuPriceParent .reveneuPrice {
    align-items: center;
}

.mains .middle .card .card-body .reveneuPriceParent .reveneuLastWeek {
    display: flex;
    align-items: center;
}

.mains .middle .card .card-body .reveneuPriceParent .reveneuLastWeek .glyph-icon {
    margin-left: 10px;
    margin-right: 10px;

}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 9px 16px !important;
}

.mt-15 {
    margin-top: 15px;
}

.mains .middle .card .card-body .icons {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 22px;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.mains .middle .card .card-body .icons.cyan {
    background: rgba(4, 209, 130, .1);
    color: #04d182;

}

.mains .middle .card .card-body .icons.blue {
    background: rgba(62, 121, 247, .1);
    color: #3e79f7;
}

.mains .middle .card .card-body .icons.volcano {
    background: rgba(255, 107, 114, .1);
    color: #ff6b72;
}

.mains .middle .card .card-body .icons.gold {
    background: rgba(255, 197, 66, .1);
    color: #ffc542;
}

.mains .middle .card .card-body h3 {
    width: 100%;
}

.tableTitle {
    margin-bottom: 30px;
}


.modal-back-drop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .9);
    z-index: 100;
}

.modals {
    background: #fff;
    -webkit-box-shadow: 0px 0px 10px -2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 10px -2px rgb(0 0 0 / 10%);
    display: flex;

    position: absolute;
    width: 60%;
    z-index: 101;
    left: 50%;
    top: 5%;

    transform: translate(-50%);
    border-radius: 7px;
}

.width40 {
    width: 40% !important;
}

.dashboardWizard {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #f9f9f9;
    background-size: cover;
    background-position: bottom;
}

.dashboardWizard>.panels {
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;

    padding-bottom: 0px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
}

.wizardParent {
    position: relative;
}

.overflowDiv {
    overflow-y: auto;
    height: 400px;
    border: 1px solid #dedede;
    padding: 10px;
    background-color: #f9f9f9;
    margin-bottom: 15px;
}

.wizard-progress {
    margin-top: 0px;
    padding-top: 60px;
    display: table;
    width: 100%;
    table-layout: fixed;
    position: relative;
}

.wizard-progress .step {
    display: table-cell;
    text-align: center;
    vertical-align: top;
    overflow: visible;
    position: relative;
    font-size: 14px;
    color: #666;
    font-weight: bold;
}

.wizard-progress .step:not(:last-child):before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 37px;
    background-color: #dad9d9;
    height: 6px;
    width: 100%;
}

.wizard-progress .step .node {
    color: #fff;
    display: inline-block;
    border: 6px solid #dad9d9;
    background-color: #fff;
    border-radius: 18px;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 25px;
    left: 50%;
    padding: 0;
    margin-left: -18px;
}

.wizard-progress .step.complete:before {
    background-color: #410099;
}

.wizard-progress .step.complete .node {
    border-color: #410099;
    background-color: #410099;
}

.wizard-progress .step.complete .node:before {

    content: "\2713";
}

.wizard-progress .step.in-progress:before {
    background: #410099;
    background: -moz-linear-gradient(left, #410099 0%, #fff 100%);
    background: -webkit-linear-gradient(left, #410099 0%, #fff 100%);
    background: linear-gradient(to right, #410099 0%, #fff 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#410099", endColorstr="#fff", GradientType=1);
}

.wizard-progress .step.in-progress .node {
    border-color: #410099;
}

.dashboardWizard .welcome {
    padding-top: 70px;
    color: #333;
    font-size: 36px;
    text-align: center;
}

.dashboardWizard .welcomeSubText {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 25px;
    color: #333;
    font-size: 21px;
}

.dashboardWizard {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #f9f9f9;
    background-size: cover;
    background-position: bottom;
}



.wizardParent {
    position: relative;
}

.wizard-progress {
    margin-top: 0px;
    padding-top: 60px;
    display: table;
    width: 100%;
    table-layout: fixed;
    position: relative;
}

.wizard-progress .step {
    display: table-cell;
    text-align: center;
    vertical-align: top;
    overflow: visible;
    position: relative;
    font-size: 14px;
    color: #666;
    font-weight: bold;
}

.wizard-progress .step:not(:last-child):before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 37px;
    background-color: #dad9d9;
    height: 6px;
    width: 100%;
}

.wizard-progress .step .node {
    color: #fff;
    display: inline-block;
    border: 6px solid #dad9d9;
    background-color: #fff;
    border-radius: 18px;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 25px;
    left: 50%;
    padding: 0;
    margin-left: -18px;
}

.wizard-progress .step.complete:before {
    background-color: #410099;
}

.wizard-progress .step.complete .node {
    border-color: #410099;
    background-color: #410099;
}

.wizard-progress .step.complete .node:before {
    content: "\2713";
}

.wizard-progress .step.in-progress:before {
    background: #410099;
    background: -moz-linear-gradient(left, #410099 0%, #fff 100%);
    background: -webkit-linear-gradient(left, #410099 0%, #fff 100%);
    background: linear-gradient(to right, #410099 0%, #fff 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#410099", endColorstr="#fff", GradientType=1);
}

.wizard-progress .step.in-progress .node {
    border-color: #410099;
}

.dashboardWizard .welcome {
    padding-top: 70px;
    color: #333;
    font-size: 36px;
    text-align: center;
}

.dashboardWizard .welcomeSubText {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 25px;
    color: #333;
    font-size: 21px;
}

.dashboardWizard .submitButton {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: #41009a;
    color: #fff;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 100;
    height: 40px;

    cursor: pointer;
    border: 1px solid transparent;
    padding: 6px 40px;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.react-tel-input .form-control {
    width: 100% !important;
    padding: 15px 14px 16.5px 58px !important;
}

.wizard0 .imgs {
    text-align: center;
    margin-bottom: 30px;
}

.wizard0 .imgs img {
    width: 60%;
    padding: 60px;
}

.wizard0 .contents {
    width: 100%;
    text-align: center;
}

.wizard0 .contents .rowTitle {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 15px;
}

.wizard0 .contents .form-check {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.wizard0 .card {
    cursor: pointer;
    transition: .5s ease;

}

.wizard0 .card:hover {
    box-shadow: 0 0 0 1px rgb(53 72 91 / 7%), 0 2px 2px rgb(0 0 0 / 1%), 0 4px 4px rgb(0 0 0 / 2%), 0 10px 8px rgb(0 0 0 / 3%), 0 15px 15px rgb(0 0 0 / 3%), 0 30px 30px rgb(0 0 0 / 4%), 0 70px 65px rgb(0 0 0 / 5%);
}



.searchOptFloat .ant-select-selection-overflow {

    height: 55px;
}

.searchOptFloat .ant-select-selector {
    overflow: auto;
}


.optSearchPanel .filterButton {
    height: 55px;
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.optSearchPanel .form-floating label {
    color: #bbb !important;
}


.searchOptFloat2 .ant-select-selector {
    height: 58px !important;
}

.searchOptFloat2 .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
}

.searchOptFloat2 .ant-select-selection-item {
    display: flex;
    align-items: center;
}

.mb0 {
    margin-bottom: 0 !important;
}